import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, Modal } from "react-bootstrap";
import PayoutRouting from "./PayoutRouting";
import PreviewIcon from "@mui/icons-material/Preview";
import RouteIcon from "@mui/icons-material/Route";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import swal from "sweetalert";
import {
  ENABLE_DISABLE_PAYOUT_ROUTING,
  UPDATE_MERCHANT,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import {
  GET_ALL_SUBMITTED_PAYOUT_ROUTING,
  GET_VIRTUAL_ACCOUNT_DATA,
} from "../../utils/constant";
import { getAllRoutingView } from "../../utils/ApiServices";
import useCheckPrivilages from "../../utils/checkPrivilages";

const PayoutSubmitted = ({
  showEditButton,
  showEnaDisaButton,
  type,
  toggleRightBar,
}) => {
  const [applications, setApplications] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [accessType, setAccessType] = useState("");
  const [routing, setRoutingWith] = useState("");
  const [mid, setmid] = useState("");
  const [companyMid, setCompanyMid] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const[refresh,setRefresh]=useState(false)
  const [editCallback, setEditCallback] = useState({
    id: "",
    callbackUrl: "",
    transferType: "",
    mappedMid: "",
    mappedWithCompanyName: "",
    usedFor: "",
    mid: "",
    requestRoute: "",
  });
  const handleShowRouting = () => setIsModalRoutingOpen(true);

  const columns = [
    { headerName: "Merchant ID", field: "mid", width: 200 },
    { headerName: "Merchant Name", field: "companyName", width: 230 },
    // { label: "SID", field: "sid", width: "auto" },
    { headerName: "Mapped With", field: "mappedWithCompanyName", width: 250 },
    { headerName: "Callback URL", field: "callbackUrl", width: 220 },
    { headerName: "Transfer Type", field: "transferType", width: 200 },
    { headerName: "Access Type", field: "usedFor", width: 200 },
    { headerName: "Route With", field: "requestRoute", width: 200 },
    // { headerName: "Action", field: "action", width: 200 },
  ];
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();
  const hasPrivilages2636 = useCheckPrivilages(2636);

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <sapan>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </sapan>
    );

    if (mappedItem.active === true) {
      mappedItem.action = hasPrivilages2636 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          {showEditButton && (
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                openDetails(
                  item.id,
                  item.callbackUrl,
                  item.transferType,
                  item.mappedMid,
                  item.mappedWithCompanyName,
                  item.usedFor,
                  item.mid,
                  item.requestRoute
                )
              }
            >
              Edit
            </button>
          )}
          {showEnaDisaButton && (
            <button
              className="btn btn-danger btn-sm"
              onClick={() => EnbDsb(item.id)}
            >
              Disable
            </button>
          )}
        </div>
      ) : null;
    }

    if (mappedItem.active === false) {
      mappedItem.action = hasPrivilages2636 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          {showEditButton && (
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                openDetails(
                  item.id,
                  item.callbackUrl,
                  item.transferType,
                  item.mappedMid,
                  item.mappedWithCompanyName,
                  item.usedFor,
                  item.mid,
                  item.requestRoute
                )
              }
            >
              Edit
            </button>
          )}
          {showEnaDisaButton && (
            <button
              className="btn btn-success btn-sm"
              onClick={() => EnbDsb(item.id)}
            >
              Enable
            </button>
          )}
        </div>
      ) : null;
    }

    return mappedItem;
  });
  const getVirtualAccountData = async (type) => {
    // setShowLoader(true);
    await apiCallFnforGet(GET_VIRTUAL_ACCOUNT_DATA + type)
      .then((response) => {
        if (response.statusCode === 200) {
          setCompanyData(response.data);
          // console.log("Company data------------------->", companyData);
        } else setCompanyData();
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setShowLoader(false);
      });
  };
  const openDetails = (
    id,
    callbackUrl,
    transferType,
    mappedMid,
    mappedWithCompanyName,
    usedFor,
    mid,
    requestRoute
  ) => {
    setEditCallback({
      id,
      callbackUrl,
      transferType,
      mappedMid,
      mappedWithCompanyName,
      usedFor,
      mid,
      requestRoute,
    });

    setIsModalOpen(true);
    getVirtualAccountData(transferType);
    setSelectedCompany(mappedWithCompanyName);
    setCompanyMid(mappedMid);
    setmid(mid);
    setAccessType(usedFor);
    setCallbackUrl("");
    setRoutingWith(requestRoute);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditCallback({
      id: "",
      callbackUrl: "",
      transferType: "",
      mappedMid: "",
      mid: "",
    });
    setAccessType("");
    setRoutingWith("");
  };

  //   const disableSID = (id) => {
  //     swal({
  //       title: "Alert",
  //       text: "Are you sure, do you want to disable?",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDisable) => {
  //       if (willDisable) {
  //         const formData = new FormData();
  //         formData.append("id", id);
  //         // setShowLoader(true);

  //         axios
  //           .put(
  //             `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/disable/${id}`,
  //             formData,
  //             {
  //               headers: {
  //                 Authentication: `Bearer ${token}`,
  //               },
  //             }
  //           )
  //           .then((response) => {
  //             // setShowLoader(false);

  //             if (response.data.statusCode === 200) {
  //               swal({
  //                 title: "Success!",
  //                 text: response.data.message,
  //                 icon: "success",
  //               });

  //               handleCloseModal();
  //               FetchData(token);
  //             } else {
  //               swal({
  //                 title: "Alert!",
  //                 text: response.data.message,
  //                 icon: "error",
  //               });
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching data:", error);
  //             // setShowLoader(false);
  //           });
  //       } else {
  //         // User clicked cancel in the swal confirmation
  //         // setShowLoader(false);
  //       }
  //     });
  //   };

  //   const enableSID =  (id) => {
  //     // Show swal confirmation before proceeding
  //     swal({
  //       title: "Alert",
  //       text: "Are you sure, do you want to enable?",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDisable) => {
  //       if (willDisable) {
  //         const formData = new FormData();
  //         formData.append("id", id);
  //         // setShowLoader(true);
  //         axios
  //           .put(
  //             `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/enable/${id}`,
  //             formData,
  //             {
  //               headers: {
  //                 Authentication: `Bearer ${token}`,
  //               },
  //             }
  //           )
  //           .then((response) => {
  //             // setShowLoader(false);

  //             if (response.data.statusCode === 200) {
  //               swal({
  //                 title: "Success!",
  //                 text: response.data.message,
  //                 icon: "success",
  //               });

  //               handleCloseModal();
  //               FetchData(token);
  //             } else {
  //               swal({
  //                 title: "Alert!",
  //                 text: response.data.message,
  //                 icon: "error",
  //               });
  //             }
  //           })
  //           .catch((error) => {
  //             // setShowLoader(false);
  //           });
  //       } else {
  //         // User clicked cancel in the swal confirmation
  //         // setShowLoader(false);
  //       }
  //     });
  //   };

  const EnbDsb = async (e, id) => {
    const EnableOrDisable = e.target.value;
    swal({
      title: "Alert",
      text: `Are you sure, you want to ${EnableOrDisable}?`,
      icon: "Warning",
      buttons: "true",
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(
          `${ENABLE_DISABLE_PAYOUT_ROUTING}${EnableOrDisable}/${id}`
        ).then((res) => {
          if (res.statusCode === 200) {
            swal({
              title: "Success!",
              text: `Payout Routing ${EnableOrDisable}successfully`,
              icon: "success",
            });
          } else {
            swal({
              title: "Failed",
              text: `Failed to  ${EnableOrDisable}`,
              icon: "Error",
            });
          }
        });
      }
    });
  };
  const handelDataChange = (e) => {
    const { name, value } = e.target;
    setEditCallback({ ...editCallback, [name]: value });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    if (editCallback.usedFor === "Api") {
      if (editCallback.callbackUrl === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Callback URL",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      callbackUrl: editCallback.callbackUrl,
      id: editCallback.id,
      mappedMid: companyMid,
      mid: mid,
      transferType: editCallback.transferType,
      usedFor: accessType,
      requestRoute: routing,
    };
    handleCloseModal();
    // setShowLoader(true);

    await apiCallFnforPut(UPDATE_MERCHANT + payload)
      //   .put(
      //     `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/updateMerchantPayoutMaster`,
      //     payload,
      //     {
      //       headers: {
      //         Authentication: `Bearer ${token}`,
      //       },
      //     }
      //   )
      .then((response) => {
        // setShowLoader(false);
        if (response.statusCode === 200) {
          swal({
            title: "Success!",
            text: "Details Updated Successfully.",
            icon: "success",
          });
          handleCloseModal();
          FetchData();
        } else {
          swal({
            title: "Alert!",
            text: response.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        // setShowLoader(false);
      });
  };

  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };

  const FetchData = async () => {
    // setShowLoader(true);
    try {
      const apiResponse = await apiCallFnforGet(
        GET_ALL_SUBMITTED_PAYOUT_ROUTING
      );
      //   setShowLoader(false);
      if (apiResponse.statusCode === 200) {
        const finalRes = apiResponse.data.map((item, i) => ({
          id: i,
          mid: item.mid,
          companyName: item.companyName,
          mappedWithCompanyName: item.mappedWithCompanyName,
          callbackUrl: item.callbackUrl,
          transferType: item.transferType,
          usedFor: item.usedFor,
          requestRoute: item.requestRoute,
        }));
        setApplications(finalRes);
        console.log(finalRes);
      } else {
      }
    } catch (error) {
      //   setShowLoader(false);
    }
  };
  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    const companyMid =
      companyData?.find((company) => company.companyName === selectedCompany)
        ?.mid || "";
    setSelectedCompany(selectedCompany);
    setCompanyMid(companyMid);
  };

  useEffect(() => {
    FetchData();
  }, [isModalRoutingOpen,refresh,setRefresh]);

  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center me-4">
          <div className="d-flex align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Payout</div>
          </div>
          <div className="mt-3">
            <CustomTip size="18" placement="top" title="Routing">
              <div className="" onClick={handleShowRouting}>
                <RouteIcon fontSize="large" />
              </div>
            </CustomTip>
          </div>
        </div>
       
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div className="table-responsive">
                    <DataGrid
                      rows={applications}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "active" || column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      
        
          <PayoutRouting  show={isModalRoutingOpen}
        onHide={handleCloseModalRouting} setRefresh={setRefresh} refresh={refresh}/>
        
     
    </>
  );
};

export default PayoutSubmitted;
