import React, { useState, useEffect } from "react";
import useApiServices from "../../utils/useApiServices";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  FETCH_VEHICLE_BY_SERVICE_TYPE,
  GET_ALL_SID,
  SAVE_AMOUNT_RULE,
} from "../../utils/constant";
import swal from "sweetalert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import CloseIcon from "@mui/icons-material/Close";

const FrmAndRoutingRule = ({ handleClose }) => {
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [cards, setCards] = useState([{
    processor: "",
    sidList: [],
    selectedSid: "",
    isDenied: true, // Default to true
    requestMode: "",
    remarkMessage: "",
    txnMinAmount: "",
    txnMaxAmount: ""
  }]);
  const [vehicles, setVehicles] = useState([])
  const [payload, setPayload] = useState([]);
  const [priority, setPriority] = useState(0);

  console.log('payload', setPayload);


  const FetchVehicleMasterByServiceCharge = async () => {

    const response = await apiCallFnforPost(FETCH_VEHICLE_BY_SERVICE_TYPE, {
      "serviceType": "UPI"
    })
    console.log('Response----->', response)
    if (response.statusCode === 200) {
      setVehicles(response?.data)
    }
    else {
      setVehicles([])
    }

  }

  const getAllSIDs = async (processor, index) => {
    try {
      if (processor) {
        const response = await apiCallFnforGet(`${GET_ALL_SID}/${processor}`);
        if (response?.statusCode === 200) {
          setCards(prevCards => {
            const newCards = [...prevCards];
            newCards[index].sidList = response?.data;
            return newCards;
          });
        } else if (response?.statusCode === 306) {
          setCards(prevCards => {
            const newCards = [...prevCards];
            newCards[index].sidList = [];
            return newCards;
          });
        }
      }
    } catch (error) {
      console.error("Error fetching SIDs", error);
    }
  };

  const handleProcessorChange = (index, processor, selectedId) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].processor = processor;
      newCards[index].selectedSid = ""; // Clear selected SID when processor changes
      getAllSIDs(selectedId, index);
      return newCards;
    });
  };

  const handleSidChange = (index, sid) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].selectedSid = sid;
      return newCards;
    });
  };

  const handleRouteChange = (index, isDenied) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].isDenied = isDenied;

      if (isDenied) {
        // If Denied is selected, clear processor and SID
        newCards[index].processor = "";
        newCards[index].selectedSid = "";
      }

      return newCards;
    });
  };

  const handleRequestModeChange = (index, mode) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].requestMode = mode;
      return newCards;
    });
  };

  const handleRemarkChange = (index, remark) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].remarkMessage = remark;
      return newCards;
    });
  };

  const handleMinAmountChange = (index, value) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].txnMinAmount = value;
      return newCards;
    });
  };

  const handleMaxAmountChange = (index, value) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[index].txnMaxAmount = value;
      return newCards;
    });
  };

  const addCard = () => {
    setCards(prevCards => [
      ...prevCards,
      {
        processor: "",
        sidList: [],
        selectedSid: "",
        isDenied: true, // Default to true
        requestMode: "",
        remarkMessage: "",
        txnMinAmount: "",
        txnMaxAmount: ""
      }
    ]);
  };

  const removeCard = (index) => {
    setCards(prevCards => prevCards.filter((_, i) => i !== index));
  };

  const validateAmounts = () => {
    for (const card of cards) {
      const minAmount = parseFloat(card.txnMinAmount) || 0;
      const maxAmount = parseFloat(card.txnMaxAmount) || 0;
      if (minAmount > maxAmount) {
        return false; // Validation failed
      }
    }
    return true; // Validation passed
  };

  const updatePayload = () => {
    const newPayload = cards.map((card, index) => ({
      domain: null,
      mid: selectedMerchant,
      processor: card.processor ? card.processor : "", // Update processor value
      priority: index + 1, // Set priority based on index + 1
      remarkMessage: card.remarkMessage,
      requestMode: card.requestMode,
      sid: card.selectedSid,
      isDenied: card.isDenied, // Update to isDenied
      txnMaxAmount: card.txnMaxAmount,
      txnMinAmount: card.txnMinAmount
    }));
    setPayload(newPayload);
  };

  useEffect(() => {
    updatePayload();

  }, [cards, selectedMerchant]);

  const submitAmountListRules = async () => {
    try {
      if (!selectedMerchant) {
        return swal({
          title: "Alert",
          text: "Please select merchant",
          icon: "warning",
        });
      }

      if (!validateAmounts()) {
        return swal({
          title: "Alert",
          text: "Minimum amount should not be greater than maximum amount for any rule",
          icon: "warning",
        });
      }

      // Check if all required fields are filled
      const isAnyCardIncomplete = cards.some(card =>
        (!card.txnMinAmount || !card.txnMaxAmount || !card.requestMode)
      );

      if (isAnyCardIncomplete) {
        return swal({
          title: "Alert",
          text: "Please fill in all required fields (Minimum Amount, Maximum Amount, Request Mode) for every card.",
          icon: "warning",
        });
      }

      const validationErrors = cards.some(card => !card.processor && !card.selectedSid && !card.isDenied);
      if (validationErrors) {
        return swal({
          title: "Alert",
          text: "Please select Processor and SID for all cards where Route is selected",
          icon: "warning",
        });
      }

      const response = await apiCallFnforPost(SAVE_AMOUNT_RULE, payload);
      if (response?.statusCode === 200) {
        setCards([{
          processor: "",
          sidList: [],
          selectedSid: "",
          isDenied: true, // Reset to default
          requestMode: "",
          remarkMessage: "",
          txnMinAmount: "",
          txnMaxAmount: ""
        }]);
        setPriority(0); // Reset priority
        swal({
          title: "Alert",
          text: "Transaction amount rule saved successfully",
          icon: "success",
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting rules", error);
      return swal({
        title: "Alert",
        text: error.message || "An error occurred",
        icon: "error",
      });
    }
  };

  const getAllMerchants = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
      if (response?.statusCode === 200) {
        setMerchantList(response?.data);
      }
    } catch (error) {
      console.error("Error fetching merchants", error);
      swal({
        title: "Alert",
        text: "No data found",
        icon: "warning",
      });
    }
  };

  useEffect(() => {
    getAllMerchants();
    FetchVehicleMasterByServiceCharge();
  }, []);

  console.log('payload', payload);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center">
        <div className="mb-3 col-md-3">
          <label className="form-label">Select A Merchant</label>
          <select
            name="merchantMid"
            className="form-control form-control-sm"
            value={selectedMerchant} // Keep the selected merchant value
            onChange={(e) => setSelectedMerchant(e.target?.value)}
          >
            <option>Select A Merchant</option>
            {merchantList?.map((mr, index) => (
              <option key={index} value={mr.mid}>
                {mr.mid}  :- {mr.merchantName}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex gap-2 justify-content-end mt-3 me-4 mb-2">
          <CustomTip size="18" placement="top" title="Frm rules">
            <div className="" onClick={addCard}>
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </CustomTip>
        </div>
      </div>

      {cards.map((card, index) => (
        <div className="row border border-light rounded p-2 mt-2 position-relative" key={index} id="frm_card">
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-2"
            onClick={() => removeCard(index)}
          >
            {/* <CloseIcon /> */}
          </button>
          <div>
            <div className="col-12 d-flex justify-content-center">
              <div className="col-xl-12">
                <div className="row">
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Minimum Amount</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Minimum amount"
                      value={card.txnMinAmount}
                      onChange={(e) => handleMinAmountChange(index, e.target.value)}
                    />
                  </div>
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Maximum Amount</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Maximum amount"
                      value={card.txnMaxAmount}
                      onChange={(e) => handleMaxAmountChange(index, e.target.value)}
                    />
                  </div>

                  <div className="mb-3 col-md-3">
                    <label className="form-label">Request Mode</label>
                    <select
                      className="default-select form-control form-control-sm wide"
                      value={card.requestMode}
                      onChange={(e) => handleRequestModeChange(index, e.target.value)}
                    >
                      <option value="">Choose</option>
                      <option value="S">S</option>
                      <option value="Slupi">Slupi</option>
                    </select>
                  </div>

                  <div className="mb-3 col-md-3">
                    <label className="form-label">Action</label>
                    <select
                      className="default-select form-control form-control-sm wide"
                      value={card.isDenied}
                      onChange={(e) => handleRouteChange(index, e.target.value === "true")}
                    >
                      <option value={true}>Denied</option>
                      <option value={false}>Route</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {card.isDenied && (
              <div className="mb-3 col-md-3">
                <label className="form-label">Remark</label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="Remark"
                  value={card.remarkMessage}
                  onChange={(e) => handleRemarkChange(index, e.target.value)}
                />
              </div>
            )}

            {!card.isDenied && (
              <div className="col-xl-12">
                <div className="row">
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Vehicle List</label>
                    {/* <select
                      className="default-select form-control form-control-sm wide"
                      value={card.processor}
                      onChange={(e) => handleProcessorChange(index, e.target.value)}
                    >
                        <option value="">Choose...</option> 
                      {vehicles.map((vehicle)=>( <option key={vehicle?.masterMerchantId} value={vehicle?.processor}>{vehicle?.vehicleName}</option>))}
                    
                    </select> */}
                    <select
                      className="default-select form-control form-control-sm wide"
                      value={card.processor}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        const selectedVehicle = vehicles.find(vehicle => vehicle.processor === selectedValue);
                        if (selectedVehicle) {
                          handleProcessorChange(index, selectedValue, selectedVehicle.masterMerchantId);
                        }
                      }}
                    >
                      <option value="">Choose...</option>
                      {vehicles.map(vehicle => (
                        <option key={vehicle.masterMerchantId} value={vehicle.processor}>
                          {vehicle.vehicleName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3 col-md-3">
                    <label className="form-label">Select SID</label>
                    <select
                      className="default-select form-control form-control-sm wide"
                      value={card.selectedSid}
                      onChange={(e) => handleSidChange(index, e.target.value)}
                    >
                      <option value="">Choose...</option>
                      {card.sidList.map((sid, sidIndex) => (
                        <option key={sidIndex} value={sid.sid}>
                          {sid.mid} :- {sid.sid}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="card-footer d-flex justify-content-between align-items-center">
        <div></div>
        <div>
          <button className="btn btn-primary" onClick={submitAmountListRules}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FrmAndRoutingRule;
