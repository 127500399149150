import React, { useEffect, useState } from "react";
import {Modal} from "react-bootstrap";
import swal from "sweetalert";
import {
    ENABLE_DISABLE_USER_MANAGEMENT,
  GET_ALL_SUB_USER,
  GET_ALL_USER,
  
} from "../../../utils/constant";

import { getTokenFromLocalStorage } from "../../../utils/tokenService";
import { jwtDecode } from "jwt-decode";
import ViewUser from "./ViewUser";
import EditUser from "./EditUser";
import { useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useApiServices from "../../../utils/useApiServices";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CreateUser from "./CreateUser";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CustomTip from "../../../common/CustomTip";

const SubmittedUser = ({ toggleRightBar, type1 }) => {
  const [submittedUser, setSubmittedUser] = useState([]);

  const [callAgain, setCallAgain] = useState(false);
 const [id, setId] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const token = getTokenFromLocalStorage();
  const [isModalRoutingOpen, setIsModalRoutingOpen] = useState(false);
  const decodedToken = jwtDecode(token);
  const { masterId } = decodedToken;
  const { prefix } = useSelector((state) => state.privilages);
  const { apiCallFnforPut ,apiCallFnforGet} = useApiServices();

  const columns = [
    { HeaderName: "User Name", field: "name", minWidth: "170" },
    { HeaderName: "e-Mail", field: "email", minWidth: "190" },
    { HeaderName: "Mobile Number", field: "mobile", minWidth: "150" },
     { HeaderName: "Action", field: "action", minWidth: "150" },
  ];

  const handleAction = (item, action) => {
    switch (action) {
      case "view":
        View(item.aggrigatorSubUserId);
        break;
      case "edit":
        Edit(item.aggrigatorSubUserId);
        break;
      case "enable":
        if (!item.active) {
          disableAggregator(item.aggrigatorSubUserId, "enable");
        }
        break;
      case "disable":
        if (item.active) {
          disableAggregator(item.aggrigatorSubUserId, "disable");
        }
        break;
      default:
        break;
    }
  };

  
  const getAll = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_USER+masterId); // Add the await keyword here

      if (response.statusCode === 200) {
        const finalRes = response.data.map((item, i) => ({
          id: i,
          name: item.name,
          email: item.email,
          mobile: item.mobile,
          action: (
            <Select
              value={selectedAction}
              onChange={(e) => {
                setSelectedAction(e.target.value);
                handleAction(item, e.target.value);
              }}
              displayEmpty
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: "8px 2px", // Optional: Adjust padding for better spacing
                height: "25px",
              }}
            >
              <MenuItem value="view">View</MenuItem>
              <MenuItem value="edit">Edit</MenuItem>
              {item.active ? (
                <MenuItem value="disable">Disable</MenuItem>
              ) : (
                <MenuItem value="enable">Enable</MenuItem>
              )}
            </Select>
          ),
        }));

        setSubmittedUser(finalRes);
      } else {
        setSubmittedUser([]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getAllSubUser = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_SUB_USER);

      if (response.statusCode === 200) {
        const finalRes = response.data.map((item, i) => ({
          id: i,
          name: item.name,
          email: item.email,
          mobile: item.mobile,
          action: (
            <Select
              value={selectedAction}
              onChange={(e) => {
                setSelectedAction(e.target.value);
                handleAction(item, e.target.value);
              }}
              displayEmpty
              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: "8px 2px", // Optional: Adjust padding for better spacing
                height: "25px",
              }}
            >
              <MenuItem value="view">View</MenuItem>
              <MenuItem value="edit">Edit</MenuItem>
              {item.active ? (
                <MenuItem value="disable">Disable</MenuItem>
              ) : (
                <MenuItem value="enable">Enable</MenuItem>
              )}
            </Select>
          ),
        }));

        setSubmittedUser(finalRes);
      } else {
        setSubmittedUser([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (prefix === "ALL") {
      getAllSubUser();
    } else {
      getAll();
    }
  }, [callAgain,prefix]);

  const disableAggregator = async (id, action) => {
    const EnableOrDisable = action === "enable" ? "enable" : "disable";

    swal({
      title: "Alert",
      text: `Are you sure, you want to ${EnableOrDisable}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);

        apiCallFnforPut(`${ENABLE_DISABLE_USER_MANAGEMENT}${action}/${id}`, "")
          .then((res) => {
            if (res.statusCode === 200) {
              swal({
                title: "Success",
                text: `Aggregator ${EnableOrDisable} Successfully.`,
                icon: "success",
              });
              setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Success",
                text: `Failed to ${EnableOrDisable} Aggregator.`,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        // User clicked cancel in the swal confirmation
      }
    });
  };

  const View = (masterMerchantId) => {
    console.log(masterMerchantId);
    setShow(true);
    setId(masterMerchantId);
  };

  const Edit = (masterMerchantId) => {
    console.log(masterMerchantId);
    setShow3(true);
    setId(masterMerchantId);
  };

  const handleClose = () => setShow(false);

  const handleClose3 = () => setShow3(false);

  const handleCreateUser = () => setIsModalRoutingOpen(true);
  const handleCloseModalRouting = () => {
    setIsModalRoutingOpen(false);
  };
  return (
    <div class="container-fluid">
      <div className="d-flex justify-content-between bg-white  ">
        <div className="d-flex justify-content-start align-items-center ">
          <button
            type="button"
            class="btn-close m-3"
            onClick={toggleRightBar}
          ></button>

          <div className="fs-2 text-dark fw-bold">Submitted User </div>
        </div>
        <div className="d-flex gap-2 justify-content-end mt-4 me-4">
          <CustomTip size="18" placement="top" title="Create User">
            <div className="" onClick={handleCreateUser}>
              <PersonAddAlt1Icon fontSize="large" />
            </div>
          </CustomTip>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <div style={{ height: "500px", width: "100%" }}>
                  <DataGrid
                    rows={submittedUser}
                    columns={columns.map((column) => ({
                      ...column,
                      renderCell: (params) =>
                        column.field === "action" ? params.value : params.value,
                    }))}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </div>
              </div>
              {/* </section> */}

              <div></div>
            </div>
          </div>
        </div>
      </div>
      <ViewUser 
      onHide={handleClose} 
      show={show}
      UserId={id}/>

      <EditUser
       onHide={handleClose3}
       show={show3} 
       UserId={id}/>
      <Modal
        show={isModalRoutingOpen}
        onHide={handleCloseModalRouting}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateUser getAll={getAll} getAllSubUser={getAllSubUser}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubmittedUser;
