import React, { useState, useEffect } from "react";
import useApiServices from "../../utils/useApiServices";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { Card, Modal } from "react-bootstrap";
import { Row } from "react-bootstrap";
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  FETCH_ALL_MERCHANT_PAYOUT_ROUTING_ONBORDING,
  GET_VIRTUAL_ACCOUNT_DATA,
  POST_MERCHANT_SAVE,
} from "../../utils/constant";

const PayoutRouting = ({show, onHide, refresh, setRefresh}) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedMid, setSelectedMid] = useState("");
  const [callbackUrlValue, setCallbackUrlValue] = useState("");
  const [route, setRoute] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyMid, setCompanyMid] = useState("");
  const [transferType, setTransferType] = useState("IMPS");
  const [returnUrl, setReturnUrl] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");

  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const location = useLocation();

  const getAllMerchantForApiTest = async () => {
    let res = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
    setMerchantData(res.data);
  };

  const getAllMerchantForOnBoardingRoutingPayout = async () => {
    let res = await apiCallFnforGet(
      FETCH_ALL_MERCHANT_PAYOUT_ROUTING_ONBORDING
    );
    setMerchantData(res.data);
  };

  useEffect(() => {
    getAllMerchantForApiTest();
  }, [refresh]);

  const getVirtualAccountData = async (type) => {
    await apiCallFnforGet(GET_VIRTUAL_ACCOUNT_DATA + type)
      .then((response) => {
        if (response.statusCode === 200) {
          setCompanyData(response.data);
        } else {
          setCompanyData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getVirtualAccountData("IMPS");
  }, []);

  const handleMerchantChange = (event) => {
    const selectedMerchant = event.target.value;
    const selectedMerchantData = merchantData?.find(
      (merchant) => merchant.merchantName === selectedMerchant
    );
    if (selectedMerchantData) {
      const { mid, payoutCallBackUrl } = selectedMerchantData;
      setSelectedMerchant(selectedMerchant);
      setSelectedMid(mid);

      if (payoutCallBackUrl) {
        setCallbackUrl("Api");
        setCallbackUrlValue(payoutCallBackUrl);
      } else {
        setCallbackUrl("");
        setCallbackUrlValue("");
      }
    }
  };

  const handleSavePayoutWithdrawal = async () => {
    if (
      selectedMerchant === "" ||
      callbackUrl === "" ||
      selectedCompany === "" ||
      transferType === ""
    ) {
      swal(
        "Error",
        "Please select a Merchant and fill in all required details",
        "error"
      );
      return;
    }
    if (callbackUrl === "Api" && callbackUrlValue.trim() === "") {
      swal("Error", "Please enter a Callback URL", "error");
      return;
    }

    const payload = {
      callBackUrl: callbackUrl === "Api" ? callbackUrlValue : "",
      mappedMid: companyMid,
      mid: selectedMid,
      transferType: transferType,
      usedFor: callbackUrl,
      requestRoute: route,
    };

    if (selectedMerchant) {
      const selectedMerchantData = merchantData.find(
        (merchant) => merchant.merchantName === selectedMerchant
      );
      if (selectedMerchantData && selectedMerchantData.payoutCallBackUrl) {
        setReturnUrl(selectedMerchantData.payoutCallBackUrl);
      }
    }

    await apiCallFnforPost(POST_MERCHANT_SAVE, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          swal("Success", "Payout saved successfully!", "success");
          setCallbackUrlValue("");
          setSelectedCompany("");
          setTransferType("");
          setCallbackUrl("");
          setSelectedMerchant("");
          setRoute("");

          getAllMerchantForOnBoardingRoutingPayout();
          onHide();
          setRefresh(!refresh);
        } else {
          swal("Error", response.message, "error");
        }
      })
      .catch((error) => {
        console.error("Error saving payout:", error);
      });
  };

  const handleSelectChange = (event) => {
    setCallbackUrl(event.target.value);
  };

  const handleRouteChange = (event) => {
    setRoute(event.target.value);
  };

  const handleCompanyChange = (event) => {
    if (selectedMerchant === "" || callbackUrl === "") {
      swal(
        "Error",
        "Please select a Merchant and choose the purpose (Used For)",
        "error"
      );
      return;
    }

    const selectedCompany = event.target.value;
    const companyMid =
      companyData?.find((company) => company.companyName === selectedCompany)
        ?.mid || "";
    setSelectedCompany(selectedCompany);
    setCompanyMid(companyMid);
    console.log(companyMid);
  };

  const handleClose = () => {
    onHide();
    setCallbackUrlValue("");
          setSelectedCompany("");
          setTransferType("");
          setCallbackUrl("");
          setSelectedMerchant("");
          setRoute("");
    setRefresh(!refresh);
  };

  return (
    <>
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4 fw-bold">Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12 mb-2">
            <div className="mb-3">
              <label className="mb-1 text-dark fs-4">Select A Merchant</label>
              <select
                id="companyDropdown"
                className="form-control"
                onChange={handleMerchantChange}
                value={selectedMerchant}
              >
                <option value="">Select a Merchant</option>
                {merchantData?.map((merchant) => (
                  <option key={merchant.mid}
                   value={merchant.merchantName}>
                  {merchant.mid} :- {merchant.merchantName}
                  </option>
                ))}
              </select>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12 mb-2">
                <div className="mb-3">
                  <label className="mb-1 text-dark fs-5">Access Type</label>
                  <select
                    id="routingPatternSelect"
                    className="form-control"
                    value={callbackUrl}
                    onChange={handleSelectChange}
                    name="routingPattern"
                  >
                    <option value="">Select</option>
                    <option value="Api">API</option>
                    <option value="Panel">Panel</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12 mb-2">
                <div className="mb-3">
                  <label className="mb-1 text-dark fs-5">Route With</label>
                  <select
                    id="routingPatternSelect"
                    className="form-control"
                    value={route}
                    onChange={handleRouteChange}
                    name="routingPattern"
                  >
                    <option value="">Select</option>
                    <option value="DECENTRO">DECENTRO</option>
                    <option value="FINZEN">FINZEN</option>
                    <option value="BRANCHX">BRANCHX</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
            <div className="w-100 d-flex justify-content-center gap-3 mb-4">
              <div
                className={`${
                  transferType === "IMPS" ? "" : ""
                }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
              >
                <div className="checkbox-wrapper-27">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      style={{ border: "1px solid grey", scale: "1" }}
                      className="form-check-input"
                      name="bankDetails"
                      checked={transferType === "IMPS"}
                      onChange={() => {
                        setTransferType("IMPS");
                        getVirtualAccountData("IMPS");
                      }}
                    />
                    <span className="checkbox__icon"> </span>
                    IMPS
                  </label>
                </div>
              </div>
              <div
                className={`${
                  transferType === "UPI" ? "" : ""
                }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
              >
                <div className="checkbox-wrapper-27">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      style={{ border: "1px solid grey", scale: "1" }}
                      className="form-check-input"
                      name="bankDetails"
                      checked={transferType === "UPI"}
                      onChange={() => {
                        setTransferType("UPI");
                        getVirtualAccountData("UPI");
                      }}
                    />
                    <span className="checkbox__icon"> </span>
                    UPI
                  </label>
                </div>
              </div>
            </div>
          </Row>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="mb-1 text-dark fs-5">
                  Select Virtual Account
                </label>
                <select
                  className="form-control"
                  id="holdAmount"
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                >
                  <option value="">Select Virtual Account</option>
                  {companyData?.map((company) => (
                    <option key={company.mid} value={company.companyName}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                {callbackUrl === "Api" && (
                  <div>
                    <label className="mb-1 text-dark fs-5">Callback URL</label>
                    <input
                      type="text"
                      id="callbackUrlValue"
                      name="callbackUrlValue"
                      placeholder="Enter Callback URL"
                      className="form-control"
                      value={callbackUrlValue}
                      onChange={(e) => setCallbackUrlValue(e.target.value)}
                      disabled={returnUrl !== ""}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              className="badge badge-rounded badge-primary mx-2 px-3 py-3"
              onClick={handleSavePayoutWithdrawal}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      </Modal.Body>
      </Modal>
    </>
  );
};

export default PayoutRouting;