import Payin from "./reportIcons/payin.png";
import Settlement from "./reportIcons/settlement.png";
import ChargeBack from "./reportIcons/chargeback.png";
import Myvendors from './reportIcons/vendor.png';
import Payout from './reportIcons/payout.png';
import Withdraw from './reportIcons/withdraw.png';
import Ekyc from './reportIcons/e-kyc.png';
import Ledger from './reportIcons/ledger.png';
import Guarantee from './reportIcons/guarantee.png';
import Reconcilation from './reportIcons/Reconcilation.png';
import CreditBalance from './reportIcons/credit_balance.png';
import Release from './reportIcons/release.png';
import WalletBalance from './reportIcons/wallet_balance.png';
import Hold from './reportIcons/hold.png';
import Deposit from './reportIcons/deposit.png';
import SettlementAdjustment from './reportIcons/settlement_adjustment.png';
import Archived from "./reportIcons/archived.png";
import PayoutTxnCallBack from "./tools/tools/payout_txn_cb.png";
import toolPayout from "./tools/tools/payout.png";
import RegisterVendor from "./tools/tools/register_vendor.png";
import TransactionStatus from "./tools/tools/txn_st.png";
import TransactionCallback from "./tools/tools/txnCallback.png";
import Vendor from "./tools/tools/vendor (2).png";
import Wallet from "./tools/tools/wallet.png";
import toolWithdraw from "./tools/tools/withdraw.png";
import MerchantDetails from "./tools/tools/MerchantDtls.png";
import HoldDepo from "./tools/tools/holddepo.png";
import PayinRouting from "./tools/tools/payinrouting.png";
import eKYC from "./tools/tools/ekyc.png";
import FlagManagement from "./tools/tools/flagmanagement.png";
import FraudManagement from "./tools/tools/fraudmanagement.png";
import MDR from "./tools/tools/MDR.png";
import uploadChargeback from "./tools/tools/uploadchargeback.png";
 import reconciliationTools from "./tools/tools/reconciliation.png";
 import pendingpayout from "./tools/tools/pendingpayout.png";
  import pendingvendor from "./tools/tools/pendingvendor.png";
  import callbackapitest from "./tools/tools/callback test.png";
  import MerchantTransaction from "./reportIcons/merchant transaction.png";
  import payoutApiTest from "./tools/tools/payoutAPITest.png";
  import SIDSubmitted from "./MastersIcon/MastersIcon/SIDSubmitted.png";
  import SIDDetails from "./MastersIcon/MastersIcon/SIDDetails.png";
  import BBPSMaster from "./MastersIcon/MastersIcon/BBPSMaster.png";
  import Onboarding from "./MastersIcon/MastersIcon/Onboarding.png";
  import MastersForm from "./MastersIcon/MastersIcon/MastersForm.png";
  import Application from "./OnboardingIcon/OnboardingIcon/Application.png";
import OnBoarding from "../../Components/CustomerSupport/OnBoarding";
import payinrouting from "./OnboardingIcon/OnboardingIcon/payinrouting.png"
import payout from "./OnboardingIcon/OnboardingIcon/payout.png";
import ekyc from "./OnboardingIcon/OnboardingIcon/ekyc.png";
import voucher from "./OnboardingIcon/OnboardingIcon/voucher.png";
import trap from "./OnboardingIcon/OnboardingIcon/trap.png"
import userManagement from "./tools/tools/userManagement.png"
import routingRules from "./tools/tools/routingRules.png"
import ViewUTR from "../images/reportIcons/view_utr.png";
export const reporticons = {
    Payin,
    Settlement,
    ChargeBack,
    Myvendors,
    Payout,
    Withdraw,
    Ekyc,
    Guarantee,
    Reconcilation,
    CreditBalance,
    Release,
    WalletBalance,
    Hold,
    Deposit,
    SettlementAdjustment,
    Ledger,
    Archived,
    MerchantTransaction,
    ViewUTR,
}
export const toolIcons ={
    PayoutTxnCallBack,
    toolPayout,
    RegisterVendor,
    TransactionStatus,
    TransactionCallback,
    Vendor,
    Wallet,
    toolWithdraw,
    MerchantDetails,
    HoldDepo,
    PayinRouting,
    eKYC,
    FlagManagement,
    FraudManagement,
    MDR,
    uploadChargeback,
    reconciliationTools,
    pendingpayout,
    pendingvendor,
    callbackapitest,
    payoutApiTest,
    userManagement,
    routingRules,
    BBPSMaster,

}

export const MastersIcons = {
    SIDSubmitted,
    SIDDetails,
    Onboarding,
    MastersForm,
    BBPSMaster,
}

export const OnBoardingIcons = {
    Application,
    payinrouting,
    payout,
    ekyc,
    voucher,
    trap,
}